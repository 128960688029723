input[type='checkbox'].checkboxCustom {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: transpanent;
  border: 1px solid #3699ff;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

input[type='checkbox'].checkboxCustom::before {
  content: '\2713';
  display: block;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  color: transparent;
}
input[type='checkbox'].checkboxCustom:checked::before {
  color: #fff;
}

input[type='checkbox'].checkboxCustom:checked {
  background-color: #3699ff;
}
