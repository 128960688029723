/* element{
--main-bg-color: #3699FF;
--main-bg-hover-color: #2689EF;
} */
@import "./../../../../_metronic/assets/sass/components/variables.custom";
@import "./../../../../_metronic/assets/sass/core/components/_variables.scss";

.SbxButton{
    // background-color: #3699FF !important;
    background-color: $sbxmain !important;
    color: white !important;
    font-size: 1em;
}

.SbxButton:hover{
    background-color: $sbxmain-hover !important;
    color: white !important;
}


.SbxButtonLight{
    background-color: $sbxmain-secondary !important;
    color: $sbxmain !important;
    font-size: 1em;
}

.SbxButtonLight:hover{
    background-color: $sbxmain-hover !important;
    color: $white !important;
}

.SbxButtonLight .current{
    background-color: $sbxmain !important;
    color: white !important;
}

.SbxButtonLink{
    background-color: transparent !important;
    color: $sbxmain !important;
    font-size: 1em;
}

.SbxButtonLink:hover{
    background-color: transparent !important;
    color: $black !important;
}