.custom-typehead {
  .form-control {
    background: #eef3f7;

    .rbt-token-removeable {
      background-color: #eaeaea;
      padding-top: 5px;
      padding-bottom: 5px;
      .rbt-token-label {
        color: #4a4a4a;
      }
      .rbt-close-content {
        color: red;
      }
    }
  }
}

.amount-input {
  padding: 0.6rem 1rem;
}

.amount-input::placeholder {
  color: #000;
  opacity: 1; /* Firefox */
  font-size: 13px;
  font-weight: 400;
}

.amount-input::-webkit-outer-spin-button,
.amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.test-build {
  display: none;
}
