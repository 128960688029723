.itemName{
    cursor: pointer;
    width: 50%;
    transition: all .3s ease-in-out;
}

.itemName:hover{
    background-color: rgb(245, 248, 250);
    color: #3699FF;
    border-radius: 10px ;
}