@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.customerWrapper {
  transition: background-color 0.3s ease;
}

.customerWrapper.active {
  background-color: $gray-200;
}

.customerWrapper:hover {
  background-color: $gray-200;
}
