.noArrows::-webkit-outer-spin-button,
.noArrows::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
/* Это для браузера Firefox: */
.noArrows {
-moz-appearance: textfield;
}
.noArrows:hover,
.noArrows:focus {
-moz-appearance: number-input;
}
/* Для других */
.noArrows::-webkit-inner-spin-button,
.noArrows::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

.w25{
    width: 25%;
}

.w50{
    width: 50%;
}

.w23{
    width: 23%;

}

.w48{
    width: 48%;
}

.w49{
    width: 49%;
}