.layout{
    position: relative;
    width: 100%; 
    /* 300px; */
    height: 100%; 
    /* 300px; */
}

.topImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 50% !important;
    /* 150px !important; */
    height: 55% !important;
    /* 170px !important; */
    /* z-index: 1; */
}

.bottomImage{
    position: absolute;
    top: 33%;
    /* 100px; */
    left: 0;
    width: 50% !important;
    /* 150px !important; */
    height: 33% !important;
    /* 100px !important; */
    /* z-index: 0; */
    
    
}