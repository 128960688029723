/* element{
--main-bg-color: #3699FF;
--main-bg-hover-color: #2689EF;
} */
@import "./../../../../_metronic/assets/sass/components/variables.custom";
@import "./../../../../_metronic/assets/sass/core/components/_variables.scss";



.DateRangePicker, .DateRangePicker input{
    // color: #3699FF;
    color: $sbxmain;
    border: none;
}
.DateRangePicker input{
    /* background-color: #F5F8FA; */
    background-color: $sbxmain-secondary;
}

.DateRangePicker:hover, .DateRangePicker:hover input{
    // color: #2689EF;
    color: $sbxmain-hover;
}