.squareLayout {
  /* Rectangle */
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #EBEDF3;
  border-radius: 5.46px;
}

.welcomeTitle {
  font-weight: 400;
  font-size: 1.5em;
  line-height: 24px;
  color: #434649;
}

.squareLayout p {
 
  font-weight: 400;
  font-size: 1em;
  line-height: 1.3em;
  /* or 150% */


  color: #434649;

}