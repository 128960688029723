//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
// $primary:       									if(isDarkMode(), #3699FF, #009EF7);
// $primary-active:    								if(isDarkMode(), #187DE4, #0095E8);
// $primary-light:    								    if(isDarkMode(), #212E48, #F1FAFF);
// $primary-inverse:  									#FFFFFF;
$primary:       									if(isDarkMode(), #3699FF, #3699FF);
$primary-active:    								if(isDarkMode(), #2689EF, #2689EF);
$primary-light:    								    if(isDarkMode(), #212E48, #F5F8FA);
$primary-inverse:  									#FFFFFF;


// Success
$success:       									if(isDarkMode(), #0BB783, #50CD89);
$success-active:    								if(isDarkMode(), #04AA77, #47BE7D);
$success-light:    									if(isDarkMode(), #1C3238, #E8FFF3);
$success-inverse:  									#FFFFFF;

// Info
$info:       									    if(isDarkMode(), #8950FC, #7239EA);
$info-active:    									if(isDarkMode(), #7337EE, #5014D0);
$info-light:    									if(isDarkMode(), #2F264F, #F8F5FF);
$info-inverse:  									#FFFFFF;

// Danger
$danger:       									    if(isDarkMode(), #F64E60, #F1416C);
$danger-active:    									if(isDarkMode(), #EE2D41, #D9214E);
$danger-light:    									if(isDarkMode(), #3A2434, #FFF5F8);
$danger-inverse:  									#FFFFFF;

// Warning
$warning:       									if(isDarkMode(), #FFA800, #FFC700);
$warning-active:    								if(isDarkMode(), #EE9D01, #F1BC00);
$warning-light:    									if(isDarkMode(), #392F28, #FFF8DD);
$warning-inverse:  									#FFFFFF;

// Custom
$custom:       									    if(isDarkMode(), #AAA800, #AAC700);
$custom-active:    								    if(isDarkMode(), #AA9D01, #A1BC00);
$custom-light:    									if(isDarkMode(), #CC2F28, #AAF8DD);
$custom-inverse:  									#AAFFFF;

// Custom
$sbxmain:       									    if(isDarkMode(), #3699FF, #3699FF);
$sbxmain-active:    								    if(isDarkMode(), #3699FF, #3699FF);
$sbxmain-hover:    								    if(isDarkMode(), #2689EF, #2689EF);
$sbxmain-secondary:    								    if(isDarkMode(), #F5F8FA, #F5F8FA);
$sbxmain-light:    									if(isDarkMode(), #C8E3FF, #C8E3FF);
$sbxmain-inverse:  									#3699FF;

// Custom
$sbxmaintext:       									    if(isDarkMode(), #ffffff, #ffffff);
$sbxmaintext-active:    								    if(isDarkMode(), #ffffff, #ffffff);
$sbxmaintext-light:    									if(isDarkMode(), #ffffff, #ffffff);
$sbxmaintext-inverse:  									#ffffff;

// Custom
$sbxdark:       									    if(isDarkMode(), #000, #000000);
$sbxdark-active:    								    if(isDarkMode(), #000, #000000);
$sbxdark-light:    									if(isDarkMode(), #ffffff, #ffffff);
$sbxdark-inverse:  									#ffffff;

// Custom
$sbxprimarylight:       									    if(isDarkMode(), #C8E3FF, #C8E3FF);
$sbxprimarylight-active:    								    if(isDarkMode(), #C8E3FF, #C8E3FF);
$sbxprimarylight-light:    									if(isDarkMode(), #ffffff, #ffffff);
$sbxprimarylight-inverse:  									#ffffff;

